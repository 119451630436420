.resDetails {
    display: flex;
    flex-direction: column;
}

.created {
    margin: 0 35px 2px 0;
    padding: 0;
    width: 50px;
}

.highlight {
    background-color: #ccd6db;
    padding: 2px 5px;
    border-radius: 4px;
}

.author {
    margin: 0 35px 2px 0;
    padding: 0;
    width: 50px;
}

.source {
    margin: 0 35px 2px 0;
    padding: 0;
    width: 50px;
}

.reservationId {
    margin: 0 35px 2px 0;
    padding: 0;
    width: 50px;
}

.detailsItem {
    display: flex;
}

.createdHour {
    padding: 2px 5px;
}
