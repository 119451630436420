.headerEmail {
    background-color: #e5ebed;
    border-radius: 10px 10px 0 0;
    height: 30%;
    padding: 10px 10px 10px 20px;
}

.headerEmail p {
    margin-bottom: 0.5rem;
    color: #003349;
}

.titleEmails {
    margin-right: 5px;
    color: #003349;
}

.emailContacts {
    flex-wrap: wrap;
    display: flex;
    align-items: center;
}
