.pillInputFrame {
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    width: 90%;
    white-space: nowrap;
    > * {
        margin: 3px;
    }
}

.input {
    display: inline-block;
    -webkit-appearance: none;
}

.inputBox {
    border-radius: 10rem;
    background-color: #ffffff;
    color: #2c3439;
    font-size: 1em;
}

.inputStyle {
    border: 0;
    border-radius: 10rem;
    &:focus {
        border-color: cornflowerblue;
        outline: none;
    }
    &::-webkit-input-placeholder {
        color: #2c3439;
    }
    &:-moz-placeholder {
        color: #2c3439;
    }
    &::-moz-placeholder {
        color: #2c3439;
    }
    &:-ms-input-placeholder {
        color: #2c3439;
    }
}

.iconPlus {
    margin: 4px;
}
