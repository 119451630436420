// Override default variables before the import
$body-bg: #000;

// Import Bootstrap and its default variables
@import "../../../../node_modules/bootstrap/scss/bootstrap";
@import "../styles/brand-colors";
@import "../styles/font-styles";

:global .innerMargin {
    margin-left: 62px;
    margin-right: 62px;
    margin-top: 20px;
}

:global .box {
    padding: 24px 31px;
    background-color: white;
    border-radius: 5px;
    width: 632px;
    margin-left: 19px;
    margin-bottom: 19px;
}

:global .boxTitle {
    font-weight: bolder;
    font-size: 1.5rem;
}

:global .flex {
    display: flex;
}

:global .columnFlex {
    display: flex;
    flex-direction: column;
}

:global .columnReverseFlex {
    display: flex;
    flex-direction: column-reverse;
}

:global .rowFlex {
    display: flex;
    flex-direction: row;
}

:global .rowReverseFlex {
    display: flex;
    flex-direction: row-reverse;
}

.test {
    background-color: transparent;
}
